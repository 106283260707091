
export default {
    methods: {
        count斤Rule(count, max) {
            if(`${count}`.match(/[^0-9|-]+/)) {
                return 0
            }

            const splitResult = `${count}`.split('-')
            if(splitResult.length == 1) {
                if(max) {
                    if(+splitResult[0] > max) return max
                }
                return +splitResult[0]
            }

            const new_second = splitResult[1].replace(/([0-9]{2}).*/, '$1')
            const res =  `${splitResult[0]}-${new_second}`
            if(max) {
                const transCount = this.$helper.transformShippingCount({count: res, force: true})
                if(transCount > max) return max
            }
            return res
        },
        countRule({count, unit, max}) {
            if(unit == '斤') {
                return this.count斤Rule(count, max)
            }
        },

    },
}
