export default {
    methods: {
        /**
         * @param {*} configs discount_config
         * @param {*} price 原價
         * @returns boolean
         */
        isValidDiscount(configs, price) {
            if (!configs) return false;

            const range = configs.map((item) => {
                return [+item.min, +item.max]
            }).sort((a, b) => a[0] - b[0])

            // 檢查是否每項的min都小於max
            if (!this.validRange(range)) {
                this.$snotify.error(null, '每項的最小值不可 >= 最大值')
                return false
            }
            // 範圍重疊就錯
            if (this.isRangeCover(range)) {
                this.$snotify.error(null, '範圍重疊')
                return false
            }
            // 優惠價格不能高於單價
            if (!this.isDiscountValid(configs, price)) {
                this.$snotify.error(null, '優惠價格不能高於單價')
                return false
            }

            return true
        },
        isDiscountValid(configs, price) {
            const res = configs.some(item => {
                return +item.price >= +price
            })
            return !res
        },
        validRange(range = []) {
            const inValid = range.some((item) => {
                return item[0] > item[1]
            })

            return !inValid
        },
        isRangeCover(range = []) {
            let min = 0,
                max = 0;
            let isCover = false;

            for (let i = 0; i < range.length; i++) {
                const target = range[i];
                const hasNext = i < range.length - 1;

                if (i == 0) {
                    min = target[0];
                    max = target[1];
                    continue;
                }

                // 檢查是否重疊
                if (target[0] < min && target[1] < min) {
                    min = target[0];
                } else if (target[0] > max && target[1] > max) {
                    max = target[1];
                } else {
                    isCover = true;
                }

                if (isCover) return true;
                if (!hasNext) return isCover;
            }
        },
        /**
         * 取得優惠價格, 不符合的返回原價
         */
        getDiscountPrice({
                             discount_config = [],
                             price,
                             quantity,
                         }) {
            if (!discount_config) return +price
            if (discount_config.length == 0) return +price

            let discounts = [];
            discount_config.forEach((config, index) => {
                // const res = quantity - (+config.min)
                // if (res >= 0)
                //     discounts.push({index, price: +config.price});
                if (quantity >= config.min && quantity <= config.max) {
                    discounts.push({index, price: +config.price})
                }
            });

            if (discounts.length == 0) return +price

            const lastIndex = discounts.length - 1
            discounts.sort((a, b) => (a.index - b.index))
            return discounts[lastIndex].price;
        },

        // 檢查數量是否位於優惠區間
        isInDiscountRange(quantity, configs = []) {
            let discounts = [];
            configs.forEach(config => {
                // const res = quantity - (+config.min)
                // if (res >= 0)
                //     discounts.push(config.price);
                if (quantity >= config.min && quantity <= config.max) {
                    discounts.push(config.price)
                }
            });

            return discounts.length > 0
        },
        isPriceLessThan50Characters(price) {
            if (price.length > 50) {
                this.$snotify.error(null, '單價最多只能輸入50個字元')
                return false
            }
            return true
        },
        isPerShippingCountLessThan50Characters(perShippingCount) {
            if (perShippingCount.length > 50) {
                this.$snotify.error(null, '數量/每出貨單位最多只能輸入50個字元')
                return false
            }
            return true
        }
    }
}